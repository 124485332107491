<template>
  <div class="news-center-content">
    <div class="list">
      <div class="item" v-for="(item, key) in list.slice(a, b)" :key="key">
        <img v-if="item.imgS" :src="item.imgS" alt="" />
        <div
          class="journalism"
          :style="{ 'margin-left': item.imgS ? '25px' : '0px' }"
        >
            <div class="right">
              <!-- <div class="index">{{ item.id }}</div> -->
              <div class="date">{{ item.year }}-{{item.month}}</div>
            </div>
          <div class="journalism-title">
            <div class="title title-ellipsis" @click="onDetails(item)">
              {{ item.title }}
            </div>
            <!-- <div class="right"> -->
              <!-- <div class="index">{{ item.id }}</div> -->
              <!-- <div class="date">{{ item.date }}</div>
            </div> -->
          </div>
          <!-- <div class="text text-ellipsis">{{ item.text }}</div> -->
          <!-- <div class="headline headline-ellipsis">{{ item.headline }}</div> -->
          <div class="details" @click="onDetails(item)">查看详情</div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="total,prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          page-size="5"
          :total="list.length"
          @current-change="numClick"
          @prev-click="prevClickHandle"
          @next-click="nextClickHandle"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  data() {
    return {
      a: 0,
      b: 5,
      list: [
      {
          title: "中科网络迎来武汉大学计算机学院专家考察，共谋AI大模型技术新篇章",
          date: "2024.08",
          year: "2024",
          month: "08-14",
          text: "2024年8月12日，武汉大学计算机学院人工智能系主任、博士生导师彭敏教授及其团队成员博士田纲副教授，专程莅临中科网络进行考察与指导。中科网络对此次的到访表示了热烈欢迎，并由公司董事长袁建华、副董事长夏继征、副总经理肖科军及软件研发中心总监商飞鹏全程陪同交流。",
          imgS: require("@/assets/imgs/news0814.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/StX7xSyI9K7tJG8oAhcJsg'
        },
      {
          title: "夏日送清凉、防暑保安康丨湖北中科网络科技股份有限公司开展“夏日送清凉，防暑保安康”慰问活动",
          date: "2024.07",
          year: "2024",
          month: "07-25",
          text: "目前，正值高温酷暑的三伏时节，为切实做好职工的防暑降温和劳动保护工作，深切关怀一线员工的身心健康，不断增强员工的安全感、幸福感、获得感。为确保安全生产、项目质量进度顺利推进，公司开展了夏日送清凉慰问活动。",
          imgS: require("@/assets/imgs/news0725.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/2y2GC5K5t1nIE9qfTAROwg'
        },
      {
          title: "百城会团队一行到协会副会长单位中科网络参观交流",
          date: "2024.07",
          year: "2024",
          month: "07-03",
          text: "2024年6月27日，深圳市安全防范行业协会常务副秘书长黄云萍，副秘书长、CPS中安网行业分析师杨儒，CPS中安网编辑陈树剑及先讯科技、西部数据国代伟仕佳杰、照彰实业等多家企业代表一行，到访协会副会长单位湖北中科网络科技股份有限公司进行座谈交流。",
          imgS: require("@/assets/imgs/news0703.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/eo6pQbd6FOLgVwBD8KZ_hg'
        },
      {
          title: "党徽闪耀 身边榜样|致敬优秀共产党员",
          date: "2024.07",
          year: "2024",
          month: "07-02",
          text: "党徽闪耀 身边榜样|致敬优秀共产党员",
          imgS: require("@/assets/imgs/news0702.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/ZlSkBUPzcno1rLixe9W_LA'
        },
      {
          title: "湖北中科网络热烈祝贺建党103周年华诞！",
          date: "2024.07",
          year: "2024",
          month: "07-01",
          text: "湖北中科网络热烈祝贺建党103周年华诞！",
          imgS: require("@/assets/imgs/news0701.jpg"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/d_iiuhKzi0ZfusbIKRD_ag'
        },
      {
          title: "中标喜讯丨凝心聚力 再添新绩",
          date: "2024.06",
          year: "2024",
          month: "06-28",
          text: "近日，湖北中科网络科技股份有限公司成功中标武汉金山办公软件有限公司弱电智能化工程项目。",
          imgS: require("@/assets/imgs/news0628.png"),
          id: "1",
          src:'https://mp.weixin.qq.com/s/r73AMuzePPAymRjAxIDiSA'
        },
      {
          title: "湖北中科网络科技股份有限公司被授予湖北省专精特新中小企业",
          date: "2024.06",
          year: "2024",
          month: "06-07",
          text: "端午前夕，武昌区科经局领导莅临湖北中科网络科技股份有限公司，颁发“湖北省专精特新中小企业“牌匾。",
          text5:
            "一行领导首先对中科网络搬迁新址予以祝贺，并参观了公司，详细了解中科网络的发展历程、企业文化、科技研发、核心产品等情况。",

          text6:
            "在随后举行的座谈会上，领导们深入调研了我司近两年的企业经营状况、业务布局以及经营过程中遇到的问题，就我司平台建设的现状、发展规划提出了意见。充分肯定了中科网络近几年的工作成绩。",

          img: require("@/assets/imgs/news1-1.png"),
          imgS: require("@/assets/imgs/news0607.png"),
          img2: require("@/assets/imgs/news1-2.png"),
          headline2: "会议现场",
          text7:
            "中科网络在今后的工作中仍会以“诚信到永远”的工作理念，以感恩、创新、共享的工作态度，以优秀的项目设计、优质的项目实施、优良的产品和最优的服务来满足市场和客户的需求。",
          id: "1",
          src:'https://mp.weixin.qq.com/s/ZnWcLfusQL-OJF260AisuA'
        },
      {
          title: "湖北中科网络科技股份有限公司董事长袁建华被授予“武汉五一劳动奖章”",
          date: "2024.04",
          year: "2024",
          month: "04-22",
          text: "“五一”前夕，武汉市总工会授予湖北中科网络科技股份有限公司董事长袁建华“武汉五一劳动奖章”荣誉称号。",
          text5:
            "一行领导首先对中科网络搬迁新址予以祝贺，并参观了公司，详细了解中科网络的发展历程、企业文化、科技研发、核心产品等情况。",

          text6:
            "在随后举行的座谈会上，领导们深入调研了我司近两年的企业经营状况、业务布局以及经营过程中遇到的问题，就我司平台建设的现状、发展规划提出了意见。充分肯定了中科网络近几年的工作成绩。",

          img: require("@/assets/imgs/news1-1.png"),
          imgS: require("@/assets/imgs/news0422.png"),
          img2: require("@/assets/imgs/news1-2.png"),
          headline2: "会议现场",
          text7:
            "中科网络在今后的工作中仍会以“诚信到永远”的工作理念，以感恩、创新、共享的工作态度，以优秀的项目设计、优质的项目实施、优良的产品和最优的服务来满足市场和客户的需求。",
          id: "1",
          src:'https://mp.weixin.qq.com/s/aXT4UCw3fAldyJhZQhUoTA'
        },
        {
          title: "贵宾来访丨中南路街道党工委书记一行调研中科网络",
          date: "2024.04",
          year: "2024",
          month: "04-12",
          text: "2024年4月12日，中南路街道党工委书记韩捷、中南路街道区域办科长张萍萍、武昌区发改局调度科科长刘思琦一行莅临我司走访调研。",
          text5:
            "一行领导首先对中科网络搬迁新址予以祝贺，并参观了公司，详细了解中科网络的发展历程、企业文化、科技研发、核心产品等情况。",

          text6:
            "在随后举行的座谈会上，领导们深入调研了我司近两年的企业经营状况、业务布局以及经营过程中遇到的问题，就我司平台建设的现状、发展规划提出了意见。充分肯定了中科网络近几年的工作成绩。",

          img: require("@/assets/imgs/news1-1.png"),
          imgS: require("@/assets/imgs/0416_news.jpg"),
          img2: require("@/assets/imgs/news1-2.png"),
          headline2: "会议现场",
          text7:
            "中科网络在今后的工作中仍会以“诚信到永远”的工作理念，以感恩、创新、共享的工作态度，以优秀的项目设计、优质的项目实施、优良的产品和最优的服务来满足市场和客户的需求。",
          id: "1",
          src:'https://mp.weixin.qq.com/s/8E26M27vPOgXyHFLvl6uMw'
        },
		{
          title: "贵宾来访丨湖北省安防协会及武汉建筑业协会领导一行到访中科网络",
          date: "2023.09",
          year: "2023",
          month: "09-23",
          text: "2023年9月12日，湖北省安全防范行业协会会长魏利、郭志刚秘书长；武汉安全防范行业协会杨剑波会长、陈虎秘书长；武汉建筑行业协会智慧建筑专业委员会陈应会长、邓红武秘书长及微创光电卢余庆总经理一行莅临中科网络调研走访。",
          text5:
            "一行领导首先对中科网络搬迁新址予以祝贺，并参观了公司，详细了解中科网络的发展历程、企业文化、科技研发、核心产品等情况。",

          text6:
            "在随后举行的座谈会上，领导们深入调研了我司近两年的企业经营状况、业务布局以及经营过程中遇到的问题，就我司平台建设的现状、发展规划提出了意见。充分肯定了中科网络近几年的工作成绩。",

          img: require("@/assets/imgs/news1-1.png"),
          imgS: require("@/assets/imgs/news1-2.png"),
          img2: require("@/assets/imgs/news1-2.png"),
          headline2: "会议现场",
          text7:
            "中科网络在今后的工作中仍会以“诚信到永远”的工作理念，以感恩、创新、共享的工作态度，以优秀的项目设计、优质的项目实施、优良的产品和最优的服务来满足市场和客户的需求。",
          id: "1",
          src:'https://mp.weixin.qq.com/s/_SHj_PCHm71ukwDGVIVQDQ'
        },
        {
          title: "贵宾来访丨武昌区领导一行到访中科网络",
          date: "2023.09",
          year: "2023",
          month: "09-22",
          text: "2023年9月19日，武昌区企业帮扶对接单位领导武昌区检察院检察长赵慧在区委统战部副部长、区工商联党组书记吕志新，区审批局局长钱刚，区大数据中心主任赵建军，区企联书记郑健、会长黄诚泽陪同下莅临我司走访调研，与我司董事长袁建华面对面交流。",

          img2: require("@/assets/imgs/news2-2.png"),
          headline2: "会议现场",
          img: require("@/assets/imgs/news2-1.png"),
          imgS: require("@/assets/imgs/news2-2.png"),
          text5:
            "袁建华董事长从公司发展历程、企业文化、科技研发、核心产品、标志性工程、运维服务和党建工作等方面详细向与会领导汇报。",

          text6:
            "随后，与会领导就公司经营情况和发展趋势进行深入了解，并对今后的发展提出了意见建议。",
          text7:
            "这次走访调研为中科网络的发展提供了有力的支持，领导的意见建议为公司的发展指明了新的方向，提供了新的机遇和前景。中科网络在今后的工作中将围绕区委、区政府的中心工作，全面贯彻政府的各项举措，进一步加强在知识产权平台建设、科研合作、人才交流等方面的深度交流，以实现企业的全方位深化发展。为推动武昌区创新、促进科技进步和经济繁荣做出积极贡献。",
          img3: require("@/assets/imgs/news2-3.png"),
          headline3: "合影",
          id: "2",
          src: "https://mp.weixin.qq.com/s/LRSpz_fQ8cD6pLzHHJqVHQ",
        },
        {
          title: "搬迁公告！！！",
          date: "2023.09",
          year: "2023",
          month: "09-20",

          img: require("@/assets/imgs/news3.png"),
          imgS: require("@/assets/imgs/news3S.png"),

          id: "3",
          src:'https://mp.weixin.qq.com/s/O9aBj5eX-6tgBqtkZKR3cA'
        },
        {
          title: "红心向党、凝聚力量丨庆七·一主题党日活动",
          date: "2023.06",
          year: "2023",
          month: "06-30",
          text: "中国共产党于1921年7月23日成立后，在反动军阀政府的残暴统治之下，只能处于秘密状态，没有公开进行活动的环境。在大革命时期，党忙于国共合作、开展工农运动和支援北伐战争，没有条件对党的诞生进行纪念。把7月1日作为中国共产党的诞辰纪念日，是毛泽东同志于1938年5月提出来的。当时，毛泽东在《论持久战》一文中提出：“今年七月一日，是中国共产党建立的十七周年纪念日”。这是中央领导同志第一次明确提出“七一”是党的诞生纪念日。",
          detail:
            " 6月29日，湖北中科网络科技股份有限公司党支部开展了庆祝建党102周年暨“庆七一”主题党日活动，党支部书记肖科军同志带领支部部分党员、入党积极分子等参加了活动。",
          headline: "活动合影",
          text6:
            "党支部书记肖科军同志领誓，佩戴党徽，面对党旗，右手握拳高举，重温铿锵有力的入党誓词，铭记初心使命，汲取奋进力量。肖科军同志表示，希望通过本次主题党日活动，能增强党员的荣誉感、归属感、使命感。在今后的工作中，能做好党员带头模范作用，用实际行动践行“红心向党，凝聚力量”的青春誓言；凝聚一心，团结互助，将学习成果转化为干事的本领，助力行业高质量发展，以优异的成绩迎接党的102岁生日。",
          img1: require("@/assets/imgs/向党旗宣誓.png"),
          img: require("@/assets/imgs/活动合影.png"),
          imgS: require("@/assets/imgs/活动合影.png"),
          img2: require("@/assets/imgs/参观武昌农民运动讲习所旧址纪念馆.png"),
          headline2: "参观武昌农民运动讲习所旧址纪念馆",
          img3: require("@/assets/imgs/参观中共五大会址纪念馆.png"),
          headline3: "参观中共五大会址纪念馆",
          img5: require("@/assets/imgs/参观毛泽东同志旧居.png"),
          headline5: "参观毛泽东同志旧居",
          text9:
            " 活动结束后，大家纷纷表示此次活动意义重大，要以此次活动为契机，进一步把思想和行动统一。将伟大的建党精神用到实际工作中，在工作不断提升自己的专业能力、综合能力。踔厉奋发、笃行不怠，为公司的发展贡献一份力量。为全面建成中国特色社会主义现代化国家、为实现中华民族伟大复兴而不懈努力。",
          id: "4",
          src:'https://mp.weixin.qq.com/s/yBmz6HywoBSeDC8rkFXBJQ'
        },
        {
          title:
            "喜报丨中科网络荣获2021-2022年度中国安防行业优秀解决方案服务商、夏继征同志荣获第五届中国安防新锐领袖荣誉称号",
          date: "2023.03",
          year: "2023",
          month: "03-29",
          text: "为贯彻落实党中央关于建设“数字中国”“更高水平平安中国”战略方针，做好产业数字化、数字产业化工作，服务公安信息化建设，进一步发掘数字安防产业综合发展潜力，引领行业高质量发展，助力“数字福建”“东南会展高地”和地方经济建设，2023年3月24日-26日，由福建省公安厅、中国安全防范产品行业协会指导，7家福建省级友好单位支持的《2023中国（福建）数字安防产业博览会》（以下简称“数安会”）在福州海峡国际会展中心隆重举行。",
          detail:
            "本次数安会，湖北中科网络科技股份有限公司荣获2021-2022年度中国安防行业优秀解决方案服务商、夏继征同志荣获第五届中国安防新锐领袖等荣誉称号。",
          headline: "2021-2022年度中国安防行业优秀解决方案服务商",
          headline1: "第五届中国安防新锐领袖",
          img1: require("@/assets/imgs/第五届中国安防新锐领袖.png"),
          img: require("@/assets/imgs/2021-2022年度中国安防行业优秀解决方案服务商.png"),
          imgS: require("@/assets/imgs/2021-2022年度中国安防行业优秀解决方案服务商.png"),
          id: "5",
          src:'https://mp.weixin.qq.com/s/lrT9TcEhgR-kvZvOf3-9Eg'
        },
        {
          title: "凝心聚力谋发展·砥砺奋进展担当",
          date: "2023.02",
          year: "2023",
          month: "02-01",
          text: "2023年1月28日，农历正月初七，中科网络2023骨干工作会议在中州府生态园成功召开。",
          img: require("@/assets/imgs/召开会议.png"),
          img1: require("@/assets/imgs/会议现场.png"),
          imgS: require("@/assets/imgs/会议现场.png"),
          text6: "会议由董事长袁建华先生主持召开。",
          img2: require("@/assets/imgs/news3-1.png"),
          img3: require("@/assets/imgs/news3-2.png"),
          text8:
            "法律顾问董继华分别就施工分包管理中的法律风险防范、企业内部承包协议的法律效力、民营企业管理人员常见刑事法律风险防范三个方面做了宣讲。",
          img5: require("@/assets/imgs/news3-3.png"),
          text9: "财务审计部曹静对财务管理制度及氚云流程宣讲。",
          img11: require("@/assets/imgs/news3-4.png"),
          text10: "保密办主任龚莉宣讲保密管理工作。",
          img7: require("@/assets/imgs/news3-5.png"),
          text11: "商务中心陈海英分享招投标注意事项及招投标法律法规的变化。",
          img8: require("@/assets/imgs/news3-6.png"),
          text12: "林强分享了销售与售前的配合。",
          img9: require("@/assets/imgs/news3-7.png"),
          text13: "项目经理陈君分享了项目经理的责任与担当。",
          img10: require("@/assets/imgs/news3-8.png"),
          text14: "项目经理刘超分享了项目全寿命周期管理及劳务工分包队伍管理。",
          text1:
            "会议上，董事长回顾了公司近年来的艰辛发展路程。特别是在三年疫情期间，公司生产经营受到严重影响。但是在党和政府的帮扶下，公司没有减员，没有减薪，克服重重困难生存了下来。“留得青山在不怕没柴烧”，为公司今后的发展奠定了牢固的基础。",
          text2:
            "二十大以后，特别是今年年初的中央经济工作会议，习近平总书记对新时代民营经济统战工作作出重要指示强调，要坚持“毫不动摇地巩固和发展公有制经济”，“毫不动摇地鼓励、支持、引导非公有制经济发展”，“把团结好、引导好民营经济人士作为一项重要任务”。国家依然大力重视民营经济，重点扶持数字经济发展，中科公司的春天来了！",
          text3:
            "在政府对民营经济的大力扶持下，公司将践行本公司的宗旨——“一个中心两个基本点”。一个中心是公司的一切工作以经济效益为中心，两个基本点一是承接优质项目，二是承接的项目顺利交付。",
          text4:
            "为了实现公司宗旨，完成工作目标。我们必须做到：一、狠抓平台建设，把平台打造成能战斗、善于战斗的常胜团队。提高平台服务管理水平，提升平台人员服务意识，转被动服务为主动服务。二、加大力度开拓市场。促进各公司部门快速拓宽渠道，拓展市场，做到立足湖北，走向全国。三、打造一个忠诚、有担当的团队。提升团队建设水平，留住优质人才。提升员工忠诚度，树立员工主动担责，勇于担责的企业文化。",
          text15:
            " 正所谓脚踏实地山让路，持之以恒海可移。相信中科公司全体员工团结在公司董事会的周围，意气风发，斗志昂扬，努力拼搏，一定能圆满完成今年的工作目标，谱写中科发展历程的新篇章！",
          img4: require("@/assets/imgs/news3-9.png"),
          text16:
            "美丽绚烂的烟花为寒冷的冬天增添了一道靓丽的风景线。晚宴上，大家把酒言欢，展望未来，对新的一年充满了希望，充满了信心。",
          id: "6",
          src:'https://mp.weixin.qq.com/s/WzZV5SdF3ehu9ez0xjF_nA'
        },
        {
          title: "中科网络携手博州国投公司打造智慧博州",
          date: "2022.07",
          year: "2022",
          month: "07-14",
          text: "7月7日—11日，湖北中科网络科技股份有限公司夏继征受华中总裁联合会邀请前往新疆博州进行商务考察。一同前往考察的有：华中总裁联合会执行会长、湖北珞珈惠金融投资有限公司总裁卢敦华、中国乡村发展协会副会长卢开国、中湖康有限公司董事长、绿色碳汇投资人黄新、湖北宝龙堂生物科技有限公司董事长刘军涛、湖北亿人农业开发有限公司董事长崔国、湖北天红机械制造股份有限公司董事长卢梦林、浙江大冲能源湖北分公司总经理王勇。",
          detail:
            "本次考察受到州党委书记、人大常委会党组书记何永慧，州党委副书记、州长巴音克西，州党委副书记、湖北省援疆工作前方指挥部党组书记、总指挥方向荣，州党委常委、常务副州长曾健，州党委常委、秘书长韩守领，州商务局党组副书记、局长欧永巴特的热情接见。",

          img1: require("@/assets/imgs/news4-2.png"),
          img: require("@/assets/imgs/news4-1.png"),
          headline:
            "州党委书记何永慧（左七）,州党委副书记、湖北省援疆工作前方指挥部党组书记、总指挥方向荣（右五）",
          headline1: "州党委副书记、州长巴音克西（左七）",
          imgS: require("@/assets/imgs/news4-1.png"),
          img2: require("@/assets/imgs/news4-3.png"),
          text7:
            "经过双方洽谈，实地考察交流后，11日中科网络与州人民政府及国投公司签订战略合作协议。基于对科技引领生活、数字化社会和中央、自治区重点项目的一致理解和把握，双方遵循对社会事业有利、对企业发展有利的原则开展项目合作。共同构建智慧城市，中科网络为博州经济事业发展贡献自己的一份力量。",
          text8:
            "双方拟共同投资成立智慧城市专业公司，共同打造智慧博州，通过构建智慧城市，强化对城市的全面感知、智能分析、精准研判、协同指挥和应急处置，重点推进政务服务“一网通办”、政府运行“一网协同”、民生服务“一码互联”、城市运行“一网统管”、社会治理“一网共治”、企业服务“一站直通”，促进城市精细化治理能力，增强市民体验感和获得感提升，将博州建设成为具有全国标杆水平的新型智慧城市。",
          text9:
            "此次考察建立了鄂博两地新平台，我司将与华中总裁联合会一起整合行业资源产业链，共享资源，共建两地，共促发展。",
          img11: require("@/assets/imgs/news4-4.png"),
          img7: require("@/assets/imgs/news4-5.png"),
          img8: require("@/assets/imgs/news4-6.png"),
          text12:
            "本次考察了阿拉山口市跨境电商分拣清关中心、粮食现代物流中心、阿拉山口金沙河面业有限公司、祥和国际物流海关监管库、中亚物流园、精河县国际公铁联运综合物流园、精河县杞瑞生物科技有限责任公司、新疆精杞神枸杞开发有限责任公司、新疆金桑湾生物科技有限公司、博乐市天博辰业矿业有限公司、博乐市万亩海棠园、博乐边合区、赛管委、新疆天莱牧业有限公司加工园区等企业和中心。",
          img9: require("@/assets/imgs/news4-7.png"),
          text13:
            "期间开展了多次座谈交流。州党委副书记、州长巴音克西在会上表示，博州投资优势显著，有四大战略投资价值和四大投资比较优势。",
          text14:
            "四大战略投资价值: 一是独有区位价值。博州是国家向西开放的最前沿，是丝绸之路经济带重要连接点。博州铁路班列外联欧亚59个城市、内接国内62个开行城市，通行量和过货量居全国陆路口岸前列。同时，博州有G30、G219国道和博乐到武汉、西安、成都、郑州等多条出疆航线，到乌鲁木齐、喀什、库尔勒、阿勒泰等8条疆内航线，交通便利、出行快捷。二是亚欧枢纽价值。博州境内阿拉山口口岸是集铁路、公路、航空、输油管道四种运输方式于一体的国家一类口岸，拥有整车进口、进境粮食、国际邮件交换站、进口活畜、进口植物种苗、进口肉类、进境水果、进口冰鲜水产品、危化品、进口中药材等10大指定口岸资质，是全国口岸资质最多、功能最完善的陆路口岸。阿拉山口口岸已成为“丝绸之路经济带”通往欧洲最便捷、最稳定的大通道。三是开放平台价值。博州拥有博乐边境经济合作区、阿拉山口综合保税区、首批国家加工贸易产业园、国家跨境电商综试区等四大国家级产业平台，建设了自治区级精河产业园区，目前正在积极争创新疆自贸区博州片区。四是资源富集价值。博州有着富集的矿产资源，已发现矿种35种。有着丰富的农副产品资源，是全国重要的优质棉出口基地、“中国枸杞之乡”——精河枸杞生产基地、国家级冷水鱼良种繁育养殖基地。",
          text1:
            "四大投资比较优势:一是要素成本优势。用工成本、能源成本、物流成本、土地成本等投资要素相较东部沿海均具有价格优势。其中，博州人均月工资2500—3000元（含五金），仅为深圳的1/4；工业用地基准价按照84元/平米执行，对重大项目采取“一事一议”办法。二是产业政策优势。除国家制定的对设在西部地区的鼓励类产业企业减按15%的税率征收企业所得税外，博州制定出台了用地扶持、厂房扶持、金融扶持、项目引进奖励等一系列产业促进政策。三是空间载体优势。博州产业空间储备充足，可充分保障优质项目用地需求。产业用地方面，博乐边境经济合作区、阿拉山口综保区、精河县工业园、温泉县小微工业园等各产业园区可供出让土地1200余亩、标准化厂房近150万平米。四是营商环境优势。博州是新疆维吾尔自治区首个平安地州，2021年12月获得全国社会治安综合治理优秀地州最高奖“长安杯”荣誉称号。博州坚信“好的营商环境就是生产力”，建立了重点招引项目“一对一”服务机制，为企业提供“管家式”“保姆式”服务，当好“无事不扰，有事马上办”的“店小二”。",
          text2:
            "依托优势资源重点发展新能源产业、新材料产业、旅游产业、棉纺产业、进出口加工产业、贸易行业等产业。并诚挚欢迎各位到博州这片热土观光旅游、考察学习、投资兴业，亲身受“亚欧支点·灵秀博州”的无穷魅力。",
          text3:
            "今后，两地企业以习近平新时代中国特色社会主义思想为核心，在企业党建工作引领下将工作的外部动力和内生动力相结合，激发员工工作热情，融通市场渠道，共同努力为安防事业的美好未来而奋斗，共同构建美好家园！",
          id: "7",
          src:'https://mp.weixin.qq.com/s/55Ny1keTDVNrwoHMiWhvhg'
        },
        {
          title:
            "喜讯：恭贺湖北中科网络被武汉市安防技术防范行业协会评为最具社会责任企业、杰出会员单位和2021年武汉市安防典型示范项目",
          date: "2022.01",
          year: "2022",
          month: "01-24",
          text: "一直以来湖北中科网络科技股份有限公司都积极参与安防行业协会活动，并且在活动中做出突出贡献，更好地为行业、企业的发展服务，以“主人翁”的姿态参与协会各项工作，为推进协会建设作出贡献。2021年，河南省遭遇极端特大暴雨袭击，人民生命财产安全遭受损失，我司积极为灾区人民捐款、捐物，主动奉献爱心，承担社会责任。在2022年1月湖北中科网络被武汉市安防技术防范行业协会评为最具社会责任企业、杰出会员单位和2021年武汉市安防典型示范项目。",
          text8:
            "获得这些荣誉，是对公司安防工程设计施工质量和技术水平不断提高的肯定，新的一年，新的征程。让我们砥砺前行，凝心聚力，融合共创，赢战未来。",
          img1: require("@/assets/imgs/news02.png"),
          img: require("@/assets/imgs/news01.png"),
          imgS: require("@/assets/imgs/news01.png"),
          img2: require("@/assets/imgs/news03.png"),
          img3: require("@/assets/imgs/news04.png"),
          id: "8",
          src:'https://mp.weixin.qq.com/s/xM1GEC2W7eNIiUdtvieTwA'
        },
        {
          title: "喜讯：湖北中科网络荣获“2021年武汉市安防典型示范项目”奖项",
          date: "2022.01",
          year: "2022",
          month: "01-14",
          text: "湖北中科网络科技股份有限公司在武汉市安全技术防范行业协会开展的“2021年武汉市安防典型示范项目”评比活动中，经2022年1月13日专家评审，我公司易家、宗关派出所智慧平安小区建设设备安装项目，成功入选“2021年武汉市安防典型示范项目”。",
          text8:
            "获此荣誉，是对公司安防工程设计施工质量和技术水平不断提高的肯定，今后湖北中科网络会不断加快安防行业的技术创新，规范工程质量的管理。",
          img1: require("@/assets/imgs/news06.png"),
          img: require("@/assets/imgs/news05.png"),
          imgS: require("@/assets/imgs/news9S.png"),
          id: "9",
          src:'https://mp.weixin.qq.com/s/oWJoJYi8wEu3ruotmqH0uA'
        },
        {
          title: "喜讯：中科网络成功取得“ITSS信息技术服务标准符合性二级”证书",
          date: "2021.11",
          year: "2021",
          month: "11-29",
          text: "近日，经中国电子工业标准化技术协会信息技术服务分会核准，湖北中科网络科技股份有限公司成功获批《ITSS信息技术服务标准符合性二级》证书。据介绍，ITSS(信息技术服务标准库)是工业和信息化部、国家标准化委牵头制定的一套成体系和综合配套的信息技术服务标准库，全面规范了IT服务产品及其组成要素，用于指导企业实施标准化和可信赖的IT服务。",
          text5:
            "公司ITSS二级资质的取得，是公司运维服务能力管理、人员、资源、技术、过程、应急和交付等方面的有效证据。ITSS资质的认定，是权威部门对公司面向社会提供高质量运维服务能力的充分认可，同时也是公司向智慧城市建设者转型的重大举措。公司将通过完善服务运维体系，在客户满意度跟踪、绩效测量等方面持续改进，不断为用户提供更加优质的服务。为客户创造更大价值。",
          img: require("@/assets/imgs/news07.png"),
          imgS: require("@/assets/imgs/news07.png"),
          id: "10",
          src:'https://mp.weixin.qq.com/s/AnwymEzGWRyig7P4SyWxAA'
        },
        {
          title:
            "10.15筑牢保密防线—中科网络赴武汉大学保密实训平台开展保密学习教育培训圆满完成",
          date: "2021.10",
          year: "2021",
          month: "10-15",
          text: "金秋十月，为加强保密意识、深入贯彻学习保密知识，湖北中科网络科技股份有限公司保密办特组织公司员工赴武汉大学信息管理学院保密教育实训平台进行保密培训。",
          detail:
            "2021年10月15日9点我司待训人员齐聚一堂，以饱满的热情出发目的地，并进行合影留念。",
          text5:
            "上午10点整，参训人员参观了窃密泄密案例警示教育展区，通过实训平台保密专职讲解员从国家政策、保密法规、泄密案例、保密防护与信息攻击技术等多个方面详细的解读保密工作的严肃性与紧迫性，使参训人员更深入学习了各类窃密泄密失密等违纪违法典型案例。",
          text6:
            "现场观摩了计算机等自动化窃密泄密技术手段演示。面对外方间谍无孔不入的攻击手段，切实感受到了“一着不慎满盘皆输”，面对日新月异的信息技术，保密工作必须慎之又慎。",
          text7:
            "在放映室，观看保密教育微电影，备受鼓舞，更觉保密工作的光荣与责任。",
          text8:
            "在实训考场，我司参训人员以考试成绩全部合格且超过90%优良的成绩为这次培训画下了圆满的句号！",
          img: require("@/assets/imgs/news08.png"),
          imgS: require("@/assets/imgs/news08.png"),
          img5: require("@/assets/imgs/news09.png"),
          id: "11",
          src:'https://mp.weixin.qq.com/s/gt-OS2lT6xrZahyMBaS7lA'
        },
        {
          title:
            "热烈祝贺湖北中科网络科技股份有限公司荣获“党史之光照亮前行之路”征文大赛二等奖。",
          date: "2021.07",
          year: "2021",
          month: "07-30",
          text: "党史如明灯，照亮前行之路；党史如清泉，洗涤心灵之尘；党史如号角，激发奋进之力。热烈祝贺湖北中科网络科技股份有限公司荣获“党史之光照亮前行之路”征文大赛二等奖。",
          text6:
            "我公司按照党中央、湖北省委、武汉市委及武昌区党委关于开展党史学习教育有关部署要求，把党史学习教育作为当前首要政治任务，坚持“学史明理、学史增信、学史崇德、学史力行”总要求，扎实推动党史学习教育不断往深里走、往实里走、往心里走。",
          text7:
            "历史属于过去，学习历史的最好方式是创造历史。我们是党史长河的一部分，但不是静静的旁观者，而是肩负使命的实践者，我们应争做党史长河上最闪亮的浪花。当前，世界面临百年未有之大变局，中华民族伟大复兴，绝不是轻轻松松、敲锣打鼓就能实现的。学习党史，就要从历史大势中把握规律引领方向，始终“站在历史正确的一边”，在危机中育新机、于变局中开新局，在历史前进的逻辑中前进、在时代发展的潮流中发展。心怀“国之大者”，增强看齐意识，不断提高政治判断力、政治领悟力、政治执行力，在工作中经常同党中央精神对表对标，不折不扣抓好党中央精神贯彻落实，把企业做大做强狠抓经济效益来报答党恩。",
          img: require("@/assets/imgs/news9-1.png"),
          img1: require("@/assets/imgs/news9-2.png"),
          imgS: require("@/assets/imgs/news9-2.png"),
          id: "12",
          src:'https://mp.weixin.qq.com/s/Hcf0aCfRt8ERhUX0UaouNA'
        },
        {
          title: "喜讯：中科网络成功获得“机电工程施工总承包叁级”证书",
          date: "2021.07",
          year: "2021",
          month: "07-06",
          text: "一往如前，奋斗不止。作为国内领先的信息化智能化建设服务运营商，中科网络经湖北省住房和城乡建设厅审核批准，成功获得《机电工程施工总承包叁级》证书。",
          text6:
            "机电工程施工总承包资质的取得，是全体员工共同努力的结果，也是业界对中科网络公司实施能力的认可，我们将依托机电工程总包资质，进一步增强公司在机电项目中的工作能力，为广大业主和合作伙伴提供更好的信息化项目建设服务。",

          img: require("@/assets/imgs/news10-1.png"),
          imgS: require("@/assets/imgs/news10-1.png"),
          id: "13",
          src:'https://mp.weixin.qq.com/s/LX_LHBV885YH74Hv0UKSpQ'
        },
        {
          title: "中科网络荣获企业诚信管理体系认证及售后服务认证证书",
          date: "2021.05",
          year: "2021",
          month: "05-25",
          text: "中科网络再增新证书。近日，中企认证评审机构对我公司诚信管理体系进行了全面细致严格的现场评审。我公司各项审核项目均符合管理体系标准，顺利通过了此次审核，成功获得诚信管理体系认证证书（证书有效期至2024年5月7日）。",
          text5:
            " 另外在售后服务方面荣获售后服务认证五星认证。五星，是售后服务认证中的最高评级。这意味着中科网络在售后服务中获得了售后领域专家的认可和肯定，达到了国家标准的较高要求，取得了行业领先的地位。",
          img: require("@/assets/imgs/news11-1.png"),
          img1: require("@/assets/imgs/news11-2.png"),
          imgS: require("@/assets/imgs/news11-1.png"),
          id: "14",
          src:'https://mp.weixin.qq.com/s/he0N9a3AwFTmBYVG6StpLQ'
        },
        {
          title: "喜讯：中科网络成功取得“音视频集成工程企业资质壹级”证书",
          date: "2021.05",
          year: "2021",
          month: "05-24",
          text: "近日，经中国音像与数字出版协会音视频工程专业委员会核准，湖北中科网络科技股份有限公司成功获批《音视频集成工程企业资质壹级》证书。此资质壹级为最高级别，取得证书后，公司可在全国范围内承接各类音频、视频、灯光、智能视讯系统工程深化设计、安装、调试及技术服务类项目（适用于各类大屏会议系统项目、智慧城市可视化展示项目）。",
          text5:
            " 音视频集成资质的取得，代表着中科网络在音视频领域系统集成能力获得业界的肯定，同时，提升了公司在同行业的竞争力，有助于进一步增强公司音视频集成服务能力，为公司音视频系统集成服务业务的拓展创造了有利条件。",
          img: require("@/assets/imgs/news12-1.png"),
          imgS: require("@/assets/imgs/news12-1.png"),
          id: "15",
          src:'https://mp.weixin.qq.com/s/VQAD6_fAECXndjkEhbVGoA'
        },

        {
          title: "2021年中科网络中高层骨干会议顺利召开",
          date: "2021.02",
          year: "2021",
          month: "02-24",
          text: "2021年2月19日，是新春佳节后的第一个工作日，湖北中科网络科技股份有限公司召开2021年中高层骨干会议。",
          detail:
            "此次会议中，袁建华董事长对2021年的工作进行了展望，他强调：在新的一年中各分公司、各部门要团结协作，圆满完成公司的既定业绩目标。",
          text5:
            "新的一年公司全体上下首要的工作任务是要狠抓一个中心，二个基本点。一个中心是：抓公司经济效益，二个基本点是：1.优质项目的承接；2.项目的完满交付。",
          text6:
            "这也是2021年工作的核心要求，将“二八定律”贯彻落实到工作中去，即：抓好主要的20%，公司各位同仁要大局为重。并对公司保密工作作出重要指示。今天的会议精神由我们的中高层传达到各位公司同仁。",
          text7:
            "在学习生活上，袁建华董事长说：要学会学习，利用碎片化的时间通过各种渠道学习专业知识。",
          text8:
            "在具体的各大项目实施上，各位项目经理的工作重点为：一要抓安全生产、二要抓主线条架构。",
          text9:
            "会议中，何莉总经理兼保密工作领导小组组长发表了讲话，她提出：在新的一年中要提高工作执行力，而如何去提高，则是从负责任的“服从、落实、回复、及时调整”四个方面来。其中回复制也是工作中重要的一个环节，做到：“凡事有交代、件件有着落、事事有回音”。同时对2021年公司保密工作做了安排部署。",
          img11: require("@/assets/imgs/news13-2.png"),
          text10:
            "在过去的一年中，无论是困难还是成绩，我们都要心怀感恩，因为困难使我们成长，感恩每位同仁的辛勤工作和无私奉献。",
          text11:
            "会议中，在汉各分公司、各部门负责人，各位骨干也分别就2021年工作做了展望，纷纷表达在新的一年中在自己的岗位上扎实落实每一项工作、团结协助、振奋一心，为中科网络贡献一切。",
          img: require("@/assets/imgs/news13-1.png"),
          imgS: require("@/assets/imgs/news13-1.png"),
          id: "16",
          src:'https://mp.weixin.qq.com/s/iF9phaLOQB7GE5k7Y_SaRw'
        },
        {
          title: "中科网络《当前防疫情况下的快速布防检测解决方案》",
          date: "2020.02",
          year: "2020",
          month: "02-27",
          text: "2020年新冠病毒来势汹汹，截止2.27日全国感染患者累计7万多。",
          img: require("@/assets/imgs/news016-1.png"),
          imgS: require("@/assets/imgs/news016-1.png"),
          text5:
            "目前疫情逐渐向好，各地复工复学将逐渐开始，但疫情持续防治不容放松，基于场景快速布控检测成为当前的迫切需求。湖北中科网络科技股份有限公司特别为当前防疫形式制定了解决方案：",
          img1: require("@/assets/imgs/news016-2.png"),
          text6: "01 群体检测",
          text7:
            "采用网络型热像仪配合200W高清网络型可见光摄像机；红外热像仪采用本机前端测温技术，前端高清网络摄像机红外温度叠加，避免网络传输延迟的问题，保障温度响应实时；实时动态热成像，多目标自动测量；远距离非接触测量，快速安全；固定安装或配移动式推车使用。",
          text8: "02 单体检测",
          text9:
            "为了便于执法人员机动灵活的对各个通道口的人员进行实时检查。系统设计采用活体手持测温热像仪对待检人员进行测温，快速查看图像和数据。",
          text10: "03 通道检测",
          img7: require("@/assets/imgs/news016-3.png"),
          text11:
            "通过非接触方式检测人体温度，可以简捷、安全、直观、准确的查找、判断人员是否存在体温异常现象，迅速采取措施解决防止发热人员流动。该模式主要用于对人流量较大的单个站点或出入口，快速布设测温安检门，安检门本地 LCD 屏可显示当前通过人员温度数值，超过设定阈值可联动安检门本地声光报警。连接网络，通过计算机上的报警管理软件可实时查看当前通过人员热力图，做到实时监测和实时报警。对于突发公共卫生事件下，该模式可以用于对公共人员快速实施无感测温，主动发现异常体温人员，提高应急事件响应效率。",
          text12:
            "热成像技术简介:热成像是通过非接触探测红外能量(热量)，并将其转换为电信号，进而在显示器上生成热图像和温度值，并可以对温度值进行计算的一种检测设备。",
          img9: require("@/assets/imgs/news016-4.png"),
          text13: "01 热成像摄像机",
          text14:
            "非接触式测温在自然界中，物体温度高于绝对零度（-273℃）就会辐射电磁波，而红外线是电磁波最为广泛的一种存在形式。热成像摄像机就是通过采集物体发出的红外电磁波，将红外信号转化成电信号，再通过信号处理系统将辐射能量即温度通过不同灰度显示出来，灰度的不同代表温度不同从而计算出物体温度，并输出便于肉眼识别的伪彩色图像。这样，热成像摄像机就很好的解决了传统测温需要人员近距离接触的问题，实现非接触式测温，减少交叉感染的风险。热成像摄像机在理想稳定工作环境状态下，设备稳定性精度最高仅能做到±0.5℃，受设备内部及外部的环境影响，如风吹、空调、人行经过等造成环境温度扰动，实际工作中误差可能达到±1℃。其实，尽管过程有误差，但测量结果却能够保持相对准确。一方面，作为普遍筛查，工作人员不需要准备测量体温，而是需要保持连续测温准确性和一致性。如果检测机器测得当前通过10人体温34~35摄氏度，接下来经过一人体温为37摄氏度，那么他是否发热已经显然易见了。另一方面，还有黑体这一测温“标尺”。",
          text1: "02 黑体",
          text2:
            "测温的“标尺”黑体，作为标定红外系统的基准源，能够吸收外来的全部红外电磁波，并且不会产生任何反射与透射，但是可以向外辐射红外电磁波的理想化物体，即黑体的辐射率与吸收率为1，透射率为0。也就是说，绝对黑体只发射红外电磁波，但不反射外界环境的电磁波，使其辐射情况只于温度有关，有效避免外界环境干扰以及自身材料影响。按照国家标准《GBT 19146-2010红外人体表面温度快速筛检仪》要求，在规定的工作环境中，筛选仪器的试验误差应不大于0.4℃，黑体的加入即可轻松实现。",
          text3: "03 热成像+黑体",
          text4:
            "无感测温作为初筛，主要目的是筛查出疑似的“漏网之鱼”， 通过将黑体设置在热成像视野范围内，利用黑体的特性开展测温标定，建立灰度与温度的准确对应关系，进行测量温度实时校正，将视频画面和个人体温对应显示，大幅度提高人体测温的测温精度，减少测温误差到±0.3℃，精准测温便于工作人员管理排查，做到早发现、早隔离、早治疗，切实、有效的控制传染源，这是对社会负责任，更是对整个疫情防控负责。",
          id: "17",
          src:'https://mp.weixin.qq.com/s/2MqMTaW21Cm7Czc3kVwZBw'
        },
        {
          title: "湖北中科网络科技股份有限公司雷神山战疫凯旋",
          date: "2020.02",
          year: "2020",
          month: "02-16",
          text: "2020年这个鼠年，过得实属惨淡，本应阖家团圆，一场突如其来的新型冠状病毒肺炎疫情袭来，打破了人们正常的生活，紧紧牵动全国人民的心。",
          detail:
            "1月25日，大年初一，火神山医院建设刚刚展开，武汉市新冠肺炎疫情防控指挥部又紧急召开调度会，决定在半个月之内再建一所雷神山医院。",
          detail1:
            "6天时间里，雷神山医院的规划总建筑面积3次增加，从5万平方米增加到7.99万平方米，床位从1300张增加至1600张，总体规模超过2个火神山医院，但工期却与火神山相当，这背后饱含着千百普通人的艰苦付出。",
          text5:
            "雷神山医院基础建设完成，但是后期弱电信息化方面建设需要专业公司专业人员火速进行，这正是我们湖北中科网络科技股份有限公司的强项。",
          text6:
            "在董事长袁建华先生的号召下，公司员工立刻响应，不论身处天南海北，仅一天的时间就完成集结进驻雷神山开始建设，这可谓是一个奇迹，也彰显着中科网络的社会责任和当担。",
          img2: require("@/assets/imgs/news14-2.png"),
          img3: require("@/assets/imgs/news14-3.png"),

          text8:
            "“我和其它的建设者没有两样，国家有难，武汉有难，我们就应该站出来。”朴实无华的话语，这是他的心声。李麟，一个90后的小伙子，他是中科网络的商务总监，工作努力。但说起具体的施工建设，他没有项目经理这么专业，但在公司驰援雷神山建设的时候，毫不犹豫挺身而出。",
          img5: require("@/assets/imgs/news14-4.png"),
          text9:
            "于是，雷神山弱电信息化建设现场，多了一个忙碌的身影，平时西装革履的他，每天变得灰扑扑的，但却心甘如怡。“我特别高兴能参加到建设中来，一想到能在这场战疫中出一份力，我浑身都是劲。”蹲着吃饭的他笑着说道，一脸的满足。但从他的手机里看到的却是父母亲人满满的担心。",
          img11: require("@/assets/imgs/news14-5.png"),
          text10:
            "施工中手受伤了，却一声不吭，打了破伤风后继续上阵。时至今日，他的父母都不知道自己的儿子的手部受伤。",
          img7: require("@/assets/imgs/news14-6.png"),
          text11:
            "时间就是生命，疫情刻不容缓，中科网络60名工人与兄弟单位一起，在国家危难之时挺身而出，6天6夜完成雷神山6个病区智能系统建设，顺利完成这个艰巨的任务并圆满交付！",
          img8: require("@/assets/imgs/news14-7.png"),
          img9: require("@/assets/imgs/news14-8.png"),
          img10: require("@/assets/imgs/news14-9.png"),
          headline10:
            "“召之能来、来之能战、战之能胜”我们做到了，我们中科网络做到了！",
          img6: require("@/assets/imgs/news14-10.png"),
          img: require("@/assets/imgs/news14-1.png"),
          imgS: require("@/assets/imgs/news14-1.png"),
          id: "18",
          src:'https://mp.weixin.qq.com/s/9_zDtpgLSmU4B7Vv42S4eQ'
        },
        {
          title: "美丽的逆行——湖北中科网络驰援雷神山建设",
          date: "2020.02",
          year: "2020",
          month: "02-11",
          text: "湖北中科网络科技股份有限公司作为一个有社会责任感的企业，董事长袁建华先生吹响动员集结号，各领导、员工积极响应。",
          detail:
            "1月25日，大年初一，火神山医院建设刚刚展开，武汉市新冠肺炎疫情防控指挥部又紧急召开调度会，决定在半个月之内再建一所雷神山医院。",
          detail1:
            "瞬间驰援雷神山医院人员已集结完成，于2月8日晚间集合奔赴武汉雷神山医院建设现场，实现了一个当天发起当天集结投入驰援建设的奇迹。虽然有忐忑，有不安……但看着你们坚定的眼神，我知道你们都是最棒的……",
          img2: require("@/assets/imgs/news15-3.png"),
          img3: require("@/assets/imgs/news15-4.png"),
          img1: require("@/assets/imgs/news15-2.png"),
          text8:
            "总经理何莉女士，一边在公司的群里组织建设人员，一边利用身边一切可利用的资源支援雷神山；副董事长夏继征先生，身先士卒，作为公司第一批驰援人员进驻工地，并安排公司驰援人员食宿；副董事长李军先生，火速联系施工队伍，参与雷神山建设。",
          text9:
            "董事长袁建华先生心系武汉、心系雷神山工程进度，工地视察，事无巨细。并一再的叮嘱，要求我们中科人工程质量一定要过硬、施工速度一定要快、员工自己一定要平安。",
          img11: require("@/assets/imgs/news15-5.png"),
          img7: require("@/assets/imgs/news15-6.png"),
          img8: require("@/assets/imgs/news15-7.png"),
          img9: require("@/assets/imgs/news15-8.png"),
          text13:
            "一切一切，在武汉封城的特殊时期，公司依然在一瞬间高效运转起来。公司在弱电信息化建设方面，是行家里手，此次承接的雷神山医院弱电信息化工程可谓是信手拈来，必会以最优质量交付使用。“召之即来，来之能战，战之能胜……”中科精神必将在驰援建设中凸显。",
          text14:
            "危难时刻，每一份力量都弥足珍贵。做好防护，不要侥幸，一个都不许落下……期待中科网络的勇士们凯旋，期待我们美丽的武汉痊愈归来。",

          img6: require("@/assets/imgs/news15-9.png"),
          img4: require("@/assets/imgs/news15-10.png"),
          img: require("@/assets/imgs/news15-1.png"),
          imgS: require("@/assets/imgs/news15-10.png"),
          id: "19",
          src:'https://mp.weixin.qq.com/s/ad3zj_6p3h08KIDZqTnYmQ'
        },
        {
          title: "襄阳汉办、襄阳商会考察团来访",
          date: "2019.06",
          year: "2019",
          month: "06-15",
          text: "6月14日（星期五）上午10:00，襄阳汉办、武汉襄阳商会一行在襄阳汉办招商科科长陶俊、襄阳商会会长袁道银的带领下莅临湖北中科网络科技股份有限公司考察，考察团人员有副会长张良、副会长袁相林、王亚萍；副秘书长耿安毅、赵琛；办公室谭芳、彭永清老总。我司总经理何莉、董事会秘书郭文锐热情接待。",
          detail:
            "我司为襄阳商会副会长单位，对考察团来访，何莉总经理致欢迎辞，表示热烈欢迎。",

          img1: require("@/assets/imgs/news16-2.png"),
          img: require("@/assets/imgs/news16-1.png"),
          imgS: require("@/assets/imgs/news16-1.png"),
          text6:
            "考察会议在我司会议室召开，会议上由我司董事会秘书郭文锐向考察团详细介绍了公司的发展历程及未来规划，并展示了我司的典型工程案例及部分核心产品与成果，受到了考察团的一致好评，对我司的业务与合作前景无限看好和期待。",
          id: "20",
          src:'https://mp.weixin.qq.com/s/SGR31HmvZHzmqFniFigpNA'
        },
        {
          title: "第四届中国智能建筑节，湖北中科网络应邀参会并荣获两大奖项!",
          date: "2019.06",
          year: "2019",
          month: "06-10",
          text: "（6月6号）第四届中国智能建筑节在福州海峡国际会展中心举办，本届智能建筑节以“新生态·筑未来”为主题，对话智能建筑行业的现状与未来，助推企业数字化转型，激活行业发展动力。",

          img1: require("@/assets/imgs/news17-3.png"),
          img: require("@/assets/imgs/news17-2.png"),
          imgS: require("@/assets/imgs/news17-1.png"),
          text5:
            "湖北中科网络科技股份有限公司作为行业领先的智慧城市解决方案提供商，应邀参加展会。",
          text6:
            "湖北中科网络科技股份有限公司成立于1998年，是国家认定的高新技术企业。2014年在新三板挂牌【股票代码430390】，是全国首批新三板试点企业。",
          text7:
            "公司专注于智慧政务、智慧国防、智慧医疗、智慧公安、智慧教育、智慧园区六大领域。是同行业领先的智慧城市解决方案提供商之一。同时，在大数据及人工智能领域与金山云达成战略合作。",
          text8:
            "中国智能建筑节组委会主办的智能建筑行业“青年杰出人才”评选活动，今年已经是连续举办的第四年。活动以“青年榜样•筑造未来”为主题，依据创新性、成长性、前瞻性等评选维度，放眼全国智能建筑圈，遴选出行业内锐意进取且坚韧实干的青年。",
          text9:
            "今年活动参与人数创历届之最，来自华东、华北、华南、西部、华中各区域的行业人才激烈角逐，数千名网友投票互动，数位专家进行评审，经过组委会的严苛评选，备受行业关注的“青年杰出人才”名单终于揭晓！",
          img11: require("@/assets/imgs/news17-4.png"),
          headline11: "我公司夏继征副董事长（右九）被评为“青年杰出人才”",
          text10:
            "为提高我国智能建筑工程项目的设计水平，进一步激励智能建筑行业设计人员的创新意识，中国智能建筑节组委会在第四届（2019）中国智能建筑节期间举办优秀设计师奖的评选活动。",
          img7: require("@/assets/imgs/news17-5.png"),
          headline7: "我公司张义总监（右三）被评为“优秀设计师”",
          text11:
            "新时期、新任务、新需求，充满机遇与挑战。智能建筑行业经过30多年的发展形成共识，创新、融合、合作、共赢成为大趋势。这是湖北中科网络科技股份有限公司厚积博发的大好时机，面向未来市场展示行业企业综合实力和核心竞争力，打造行业知名品牌,并努力成为建设领域和相关行业的技术支撑和行业标杆。",
          id: "21",
          src:'https://mp.weixin.qq.com/s/jM5uJI6G-x3An9F5Bo6rvQ'
        },
        {
          title: "厚积一朝薄发，再创中科辉煌",
          date: "2019.02",
          year: "2019",
          month: "02-13",
          img: require("@/assets/imgs/news22-1.png"),
          imgS: require("@/assets/imgs/news22-1.png"),
          text5:
            "岁月不居，时节如流，乍暖还寒春已至，欢度佳节再昂首。在这个中华民族普天同庆的新春佳节里，湖北中科网络科技股份有限公司也隆重举办了新一年度的新春团拜会，用佳肴犒劳过往，用歌舞迎接朝阳，更用豪情和壮志捍卫着心中的梦想。",
          img1: require("@/assets/imgs/news22-2.png"),
          headline1: "中科网络董事长袁建华先生",
          text6:
            "新春年会启幕，袁建华董事长诚挚地祝福全体员工及嘉宾新年快乐万事如意，随后用简洁精炼的语言回顾这一年来中科网络经历的行业生态变革和我们一次次的创新突破，肯定了公司兄弟姐妹们作出的诸般努力和奋斗成果，接着为公司2019乃至往后三年的企业布局作了蓝图规划，清晰的思路和坚定的决心让大家在新的征程上更加意气风发、信心满满。随后，中科网络股东及董事郑健先生，也对全场来宾送出了新春祝福和铿锵有力的新年致辞。",
          img2: require("@/assets/imgs/news22-3.png"),
          headline2: "中科网络董事郑健先生",
          text7:
            "接下来便是共赏节目的时间，暂停疲惫的身躯，舒缓劳累的心灵，现场的全体员工和嘉宾们难得忙碌后的惬意，静静感受着艺术的气息。这里有热情高亢的歌声，有婉转悠扬的小提琴，有嗨翻全场的小号三重奏，还有身姿妙美的舞蹈，最令人欢喜和激昂的是中科员工子女们的互动游戏和会议现场的抽奖环节，瞬间便将新春佳节的喜庆推向了会场的高潮！",
          img3: require("@/assets/imgs/news22-4.png"),
          text8:
            "空气中洋溢着孩子们的欢歌笑语，舞台上弥漫着幸运儿沉甸甸的好运，无论舞台多么宽，无论企业多么大，我们真真切切能感受到，这是一种家的贴心和温暖。回味喜悦和欢心的背后，每一个辛勤劳作的日子，每一段不知疲惫奔跑的旅程，中科网络这个大家庭的成员们，总是手拉着手，肩并着肩，用我们团结的力量，和一往无前的勇气，共同打造工作和生活的温馨港湾。",
          img5: require("@/assets/imgs/news22-5.png"),
          text9:
            "舞台书写着华章，酒杯承载着希望，纵有心绪千百万，不诉衷肠酒一壶。所有的文艺节目结束后，中科网络的高管团队们登台亮相，给全场的中科家人们颂祝酒词，祝愿大家新的一年身体健康，万事如意，财源滚滚，心想事成。",
          img11: require("@/assets/imgs/news22-6.png"),
          text10:
            "临近三个小时的节目活动到达尾声，当大家拿起酒杯的那一刻，新春团拜会的晚宴正式开始，大家觥筹交错，欢歌笑语，诉说着各自的欢快今宵。殊不知几时起，外场的篝火点起，酒足饭饱之后，大家愉快的围在一起，载歌载舞，用饱满的热情和积极的状态展望中科更美好的未来。",
          img7: require("@/assets/imgs/news22-7.png"),
          id: "22",
          src:'https://mp.weixin.qq.com/s/gcSHOAp7dlhVHxfTgysvng'
        },
        {
          title: "历经二十载，腾飞创未来",
          date: "2018.03",
          year: "2018",
          month: "03-01",
          img: require("@/assets/imgs/news23-1.png"),
          imgS: require("@/assets/imgs/news23-1.png"),
          text5:
            "金鸡鸣旧岁，玉犬旺新年。时光荏苒，刹那芳华，在中华民族普天同庆的春节里，湖北中科网络科技股份有限公司也迎来了企业发展的又一个里程碑，从1998年创立至今，企业也即将跨越自身二十年的心路历程，中科网络新春团拜会，让我们见证，二十载别样的风采。",
          text6:
            "2月9日，农历小年刚过，湖北中科网络科技股份有限公司在巴山夜雨总督府举办了一年一度的新春团拜会，参会的除了中科网络的全体员工和家属，来自社会各界的领导、专家、客户和朋友们也都应邀前往，会场全程热闹非凡，处处洋溢着欢乐的海洋和饱满的热情。",
          img2: require("@/assets/imgs/news23-2.png"),
          text7:
            "会议在中科网络员工自编自导的节目《兵马俑鼓》隆隆的鼓声中震撼开场，紧接着袁建华董事长隆重登场，作为中科网络的创始人，他对参会的各位来宾、员工及员工家属表达了衷心的感谢和诚挚的祝福，祝福大家新的一年阖家幸福，事业兴隆，身体健康，万事如意。",
          img3: require("@/assets/imgs/news23-3.png"),
          text8:
            "紧接着中科网络何莉总经理就2017年经营暨2018年发展规划作了深度报告，中科网络在2017年市场环境变化的情况下业绩平稳，并为2018年企业发展的爆发奠定了良好的技术和市场基础。2017年公司分别开拓了青海、浙江、江苏、北京、四川等新兴业务市场，并成立了江苏分公司、浙江分公司和建筑智能化事业部，内部架构做了多次优化，确保了公司管理的高效运转和可持续发展。在技术积累方面，公司启动的人工智能、大数据、人脸识别等方面的技术研发成果渐显，部分产品已在客户单位上线调试，同时这些技术的突破也获得了相关部门的高度认可，公司先后获得了武汉市科技局、武汉市科协等单位共同授予的“武汉市十大科技创新示范企业”荣誉和“武汉市技术研发中心”认证称号。最后，在何莉总经理的号召下，中科网络核心管理团队在团拜会现场庄严宣读“宣誓词”，为2018年公司的快速发展许下了庄重的诺言。",
          text9:
            "下半程的会议里，是参会嘉宾们的视觉盛宴，深情的国标、激情的拉丁、可爱的小品、欢快的草裙舞、豪迈的歌曲演唱，庄重的书法赠礼，各种精彩的节目纷至沓来，由专业乐团带来的小号演奏更是让会场进入了高潮，全场观众集体沸腾，每个角落都是家人们的欢呼和尖叫，大家尽情享受着节日带来的喜庆和团聚的狂欢，尽情享受着企业年会带来的家的幸福。",
          img11: require("@/assets/imgs/news23-4.png"),
          headline11: "拉丁舞表演",
          img7: require("@/assets/imgs/news23-5.png"),
          headline7: "草裙舞表演",
          img8: require("@/assets/imgs/news23-6.png"),
          headline8: "书法赠礼",
          img9: require("@/assets/imgs/news23-7.png"),
          headline9: "小号嗨翻全场",
          img10: require("@/assets/imgs/news23-8.png"),
          headline10: "诗歌朗诵",
          text14:
            "最后，董事长携夫人共同为我们演绎了一首原创的诗歌朗诵，深情饱满，气势豪迈，为中科网络2018年的梦想奏响了华美的启航乐章。美酒不停，杯盏不歇，新春团拜会的饕餮盛宴上，大家把酒言欢，共叙佳话。在一轮轮精彩的游戏和抽奖活动中，大家踊跃参加，时不时还会出现些神秘的精彩大奖。最让人心潮澎湃的，还是举杯的那一刹那，长期驻外的同事精彩团聚，好久不见的哥们再度相遇，一句话，一声吼，全都在酒里。",
          img6: require("@/assets/imgs/news23-9.png"),
          text3:
            "二十年的奋斗岁月，二十载的中科风采，中科网络用一场喜气洋洋的团拜会诠释了企业发展道路上的一幕幕精彩，更用一种坚持不懈的豪情书写了一部峥嵘岁月的励志篇章。在新的一年里，中科网络祝大家新年快乐，生意兴隆，身体健康，万事如意。",
          img4: require("@/assets/imgs/news23-10.png"),
          id: "23",
          src:'https://mp.weixin.qq.com/s/3Y4oAWg23H4icCHTi5_KPw'
        },
        {
          title: "中科网络建筑智能化事业部成立啦！",
          date: "2017.11",
          year: "2017",
          month: "11-20",
          text: "深秋入境，桂满香园，正是丰收好时节。",
          detail:
            "而就在此时，美丽的汤逊湖畔，中科网络建筑智能化事业部也正式成立啦。",
          img1: require("@/assets/imgs/news18-2.png"),
          img: require("@/assets/imgs/news18-1.png"),
          imgS: require("@/assets/imgs/news18-1.png"),
          text5:
            "在中科网络建筑智能化事业部成立的揭牌仪式上，由袁建华董事长致开幕词，表达对事业部的认可和成长的希望，紧接着中科网络总经理何莉全程主持了整个授牌仪式，在一片热情和温馨的氛围中，传出一阵阵热烈的掌声和欢笑，美好的开端也预示着我们建筑智能化事业部今后的发展蒸蒸日上。",
          text6:
            "授牌仪式结束后，董事长带领总公司团队与事业部成员进行了深入沟通，分别就创业历程、企业文化、业务开拓及未来展望等多个方向进行了工作指导和意见交流。建筑智能化事业部姚军总经理也就事业部目前的现状和未来的发展作出全面的介绍，并在事业部努力发展过程中，就公司总部在整体规划和行业资源整合方面的成熟经验进行了交流，一致为了实现公司战略目标的达成，同时也确保公司在建筑智能化领域的行业地位。",
          img2: require("@/assets/imgs/news18-3.png"),
          img3: require("@/assets/imgs/news18-4.png"),
          text8:
            "在紧张而又充实的两个小时交流过程中，总部团队展示了中科网络成立至今的各个精彩篇章，公司发展的心路历程历历在目，建筑智能化事业部同事对公司整体文化、制度及战略进行了很好的学习和探讨，纷纷表示公司提供如此优秀的平台，更利于企业的快速发展，也更利于全体股东和员工的快速成长，在中科网络未来的发展道路上，大家都将不遗余力的为公司走向更高更广阔市场的目标而不断努力。",
          img5: require("@/assets/imgs/news18-5.png"),
          headline5: "中科网络领导合影",
          id: "24",
          src:'https://mp.weixin.qq.com/s/zN8UItbaSgdP_8zRQBzWcw'
        },
        {
          title: "中科奋斗不止，亮剑2017",
          date: "2017.03",
          year: "2017",
          month: "03-02",
          text: "江城故里辞旧岁，和风送暖迎新春。又是一个新的年度，神州大地万象更新，祖国发展日益辉煌，18年经久不衰的中科网络，又将在智慧城市建设的道路上，演绎着一幕幕扣人心弦的绝世风华。",
          detail:
            "新年伊始，金鸡报晓，湖北中科网络科技股份有限公司的全体同仁、员工家属以及多年来支持中科网络发展的客户和嘉宾朋友们齐聚一堂，以一场主题为“感恩一路，一起前行”的新春团拜会拉开了中科网络2017年扬帆起航的精彩序幕。",
          img1: require("@/assets/imgs/news19-2.png"),
          img: require("@/assets/imgs/news19-1.png"),
          imgS: require("@/assets/imgs/news19-1.png"),
          text5:
            "团拜会紧紧围绕“感恩.前行”的主题，以首篇“勿忘初心”的“沙画中科”叙述中科网络创业十八年的艰辛历程，全场嘉宾站在艺术的角度更深刻的感知公司的不断发展和奋斗轨迹，从内心去感触公司成长道路上一幕幕精彩的过往，待袁建华董事长及夫人即兴作诗并现场朗诵的精彩演出时，团拜会迅速进入会议当晚的第一波高潮，神情高亢激昂，诵读铿锵有力，如江河奔流不息，如急舟乘风破浪，现场嘉宾纷纷为这样的公司和这样的掌舵人鼓掌叫好。",
          text6:
            "第二篇章“锦绣未央”在观众怀念过往的高潮后出现，精彩的表演让大家迅速从沉醉往昔的氛围里醒了过来，平复下激动的心情，太极、小品、独唱、合唱、幸运大抽奖，一个个炫丽的节目完美上演，深深地记录了一幅幅欢喜快乐的画面。中篇“感恩的心”由何莉总经理年度总结及新年展望开始，奖励和感谢了一批批优秀的中科员工们，大家深深体会到了，自己努力付出的背后，有公司一直默默的关注和支持，颁奖词的宣读更是让大家深深领悟到了企业对员工的感恩和关怀。会议的现场，员工又即兴以多种方式表达了对公司的感谢和支持，2017年，中科网络的全体同仁们将同心同德，携手共进，坚持不懈的去实现我们心中的“中科梦”。第四篇“再创辉煌”和末篇“饕餮盛宴”，全场嘉宾尽情的高歌、尽兴的把酒，觥筹交错，杯盘狼藉，我们用最澎湃的内心和最旺盛的斗志举杯，向着中科网络更高更远的目标进军。",
          img2: require("@/assets/imgs/news19-3.png"),
          text7:
            "团拜会后，全体员工享受温馨的春节假期，中科网络的核心经理层们，依然没有停止前进的步伐。暂别都市的喧嚣，稍离街道的繁华，寻一处世外桃源，淡泊过往，憧憬明天，山湖温泉有我们最美的足迹。",
          text8:
            "核心经理层办公会上，总经理率先对全年经营做了全盘总结，各负责人对部门工作也进行深刻剖析和高度反省，并提出了各部门新年管理和部署的新策略，从部门分工、人才引进、制度管理、文化建设等多个方向筹备各阶段的计划和措施。会议结束后，褪去一身的疲劳，大家赤诚相待，共赴温泉，用愉悦的身心和澎湃的内心向着2017年共同的经营目标而努力。",
          text9:
            "春节方过，历炼归心，正月初八，中科网络的全体员工们齐聚古龙山庄，以侠客般的豪情和斗志开展两天的拓展训练和员工大会。",
          img11: require("@/assets/imgs/news19-4.png"),
          text10:
            "在董事长的高亢致辞后，拓展教练正式开始了严格的拓展训练，军姿军容，队列练习，高空抓杠、高空断桥、丛林CS，一项项极具挑战的项目突袭而来，长期工作在一线的中科同仁们，以新的方式，迎来胆识和毅力的考验。",
          img7: require("@/assets/imgs/news19-5.png"),
          text11:
            "在拓展训练中，大家激情满满，全力执行，尽管艰辛的过程中，流淌的汗水和噙着的泪水始终掺杂，肢体的酸痛和心灵的畏惧始终交错，同志们依然坚定，在自己的努力和同事的鼓励下奋勇前行，在蓝天白云的青龙山脉完成了一次身心的历练。晚间的篝火照亮前行的目标，美味的烧烤畅谈明日佳话，这一次拓展，让每一位中科网络的同仁们，在向目标进发的征程中，做足“挑战极限，突破自己”的充分准备。",
          img8: require("@/assets/imgs/news19-6.png"),
          text12:
            "紧接着的第二天，全体中科同仁们在一起召开了新年度的工作部署会议，会议给2016年公司和个人取得的成绩给予高度肯定，并且在新的年度计划中，公司给出明确的以“智慧城市建设者”为目标的业务方向，确定了加强文化建设、增加人才储备、简化工作流程等多项重要措施，会议最激动人心的环节，在公司监事会主席的见证下，公司核心业务高管现场签订个人年度绩效考核责任状，用勇敢的担当和踏实的作风许下了新年的愿望与承诺。",
          img9: require("@/assets/imgs/news19-7.png"),
          text13:
            "花灯璀璨逢盛世，锣鼓铿锵颂丰年。万家灯火的元宵节，亲爱的中科家人们已提前做足了准备，在公司开启了欢欢喜喜闹元宵的活动。",
          img10: require("@/assets/imgs/news19-8.png"),
          text14:
            "方才走出公司的电梯口，美味汤圆的香味便扑鼻而来。人才济济的大堂里，不仅有媲美大厨的汤圆西施，更有学富五车的灯谜大神，不仅有夹汤圆的筷子高手，更有肺活量超群的“气功大师”，最激动人心的时刻，莫过于“摸福墙、拆红包“的游戏环节，欢声笑语充盈了办公室，喜庆红包装满了小裤兜，带着新年的喜庆，带着饱满的热情，中科网络的同仁们将在各个平凡而伟大的岗位上，为公司主板上市、行业领先的目标而不懈努力！",
          id: "25",
          src:'https://mp.weixin.qq.com/s/rChHql9c1gw28EopLVUwZw'
        },
        {
          title: "金猴纳万福 中科迎新春",
          date: "2016.03",
          year: "2016",
          month: "03-17",
          text: "你在荆楚的大地上喜迎新春，我在江城的岁月里欢度佳节。2016年2月3日，在一片喜气洋洋的氛围中，湖北中科网络科技股份有限公司的全体成员、员工家属以及多年来支持中科网络发展的客户和嘉宾朋友们再次聚首，拉开了中科网络一年一度的年会序幕。",
          img: require("@/assets/imgs/news26-1.png"),
          imgS: require("@/assets/imgs/news26-2.png"),
          text5:
            "年会的第一个部分，由中科网络新任总经理何莉女士主持2015-2016年度工作会议。新年方至，万象更新，何总用简单朴实的语言指明了2016年公司经营的战略方向，并对中科新一年度的业绩指标做了保守预估，预计2016年公司经营业绩将实现1.5亿级的突破。并且，在公司管理优化、人才培养、员工福利等多个方面，何总分别进行了简要阐述，获得了一阵阵期盼和鼓励的掌声，在以”中科明天更美好“的呼声中，全体员工热情洋溢的展望新历程的到来。",
          img1: require("@/assets/imgs/news26-2.png"),
          text6:
            "随后中科网络董事长袁建华作出了重要的工作总结和新年规划，号召大家在新的一年中，全力支持何总经理的经营方针，争取在新的一年里，公司的业绩快速提升，员工的收入显著增加，让中科网络成为大家“追逐事业、享受生活”的平台，成为全体员工拼搏努力挥洒汗水之后可以温馨停靠的家。领导讲话一波又一波的高潮过后，上一年度的优秀员工们在热烈的掌声和激昂的奏乐中被邀请上台，这是他们辛勤付出的回报，更是他们应得的荣耀，在每一位优秀员工的获奖词中，我们体会到他们每个深夜不为人知的努力，我们感受到他们伟为达目标挑灯夜战的艰辛，简单的语言描绘的是一幅幅拼搏冲刺的画面，源源不断的掌声是中科人对优秀员工的肯定，对榜样的学习和认可，对追逐梦想拼搏事业的万丈豪情。",
          img2: require("@/assets/imgs/news26-3.png"),
          text7:
            "昨天的画面已经定格，今天的自己需要更加努力，为的是明天的故事里有我的篇章。正当大家踌躇满志的规划自己的新年目标和计划时，年会的第二项议程悄然而出，在新年到来的倒计时敲响后，中科员工们在百忙之中抽空自编自导的文艺演出闪亮登场。",
          img3: require("@/assets/imgs/news26-4.png"),
          text8:
            "独唱、二胡、配双簧，魔术、狂欢、合唱团。一轮又一轮精彩的节目闪烁光芒，一阵又一阵响亮的掌声轰动全场，当观众们还在方才的工作会议中规划梦想，演员们早已在精彩的舞台里激起千层浪。",
          img5: require("@/assets/imgs/news26-5.png"),
          text9:
            "这不，就连一向调皮捣蛋的熊孩子，都忍不住窜上舞台静静的欣赏，欣赏静静，欣赏着怎么想静静......",
          img11: require("@/assets/imgs/news26-6.png"),
          text10:
            "梦想伴佳节起舞，欢乐共良宵一夜，最后，在意犹未尽的节目序曲中，年终尾牙的香味扑面而来，年会的主题曲又变成了美丽的饕餮盛宴。值此良宵佳节，中科网络的全体同仁们神采奕奕，尽享天下美食，把酒言欢，再创中科辉煌。",
          id: "26",
          src:'https://mp.weixin.qq.com/s/V-MX_FrzO-SN17f_VGSdAQ'
        },
      ],
    };
  },
  methods: {
    onDetails(item) {
      window.open(item.src,'_blank');
      // window.location.href=item.src;
      // sessionStorage.setItem("title", JSON.stringify(item));
      // router.push("/newsDetail");
    },
    onDetails1(item) {
      sessionStorage.setItem("title", JSON.stringify(item));
      router.push("/newsDetail");
    },

    numClick(value) {
      this.a = (value - 1) * 5;
      this.b = (value - 1) * 5 + 5;
    },
    prevClickHandle(value) {
      this.a = (value - 1) * 5;
      this.b = (value - 1) * 5 + 5;
    },
    nextClickHandle(value) {
      this.a = (value - 1) * 5;
      this.b = (value - 1) * 5 + 5;
    },
  },
};
</script>

<style lang="less">
.news-center-content {
  .list {
    width: 60%;
    margin: 0 auto;
    .item {
      width: 100%;
      display: flex;
      margin-top: 6rem;
      height: 19rem;
      img {
        width: 30rem;
        height: 19rem;
      }
      .journalism {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .journalism-title {
          display: flex;
          justify-content: space-between;
          .title {
            color: black;
            font-size: 2rem;
            margin-top: -2rem;
          }
          .title:hover {
            cursor: pointer;
            text-decoration: underline;
          }
          .right {
            .index {
              text-align: right;
              color: #334c87;
              font-weight: blold;
              font-size: 2rem;
            }
            .date {
              color: #333;
              font-size: 2rem;
            }
          }
        }
        .text {
          color: #333;
          text-indent: 2em;
          letter-spacing: 0.2px;
        }
        .details {
          color: #1e4b8a;
        }
        .details:hover {
          cursor: pointer;
          text-decoration: underline;
        }
        .headline {
          color: transparent;
          text-align: center;
        }
      }
    }
  }
  .pagination {
    margin-top: 5rem;
    margin-bottom: 5rem;
    display: flex;
    justify-content: right;
  }
  .text-ellipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .headline-ellipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  .title-ellipsis {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }
  //组件样式
  .el-pagination__total {
    color: #1f4a89 !important;
  }
  .active {
    background-color: #1f4a89 !important;
  }
  .btn-prev {
    span {
      padding: 0 0.6rem;
      border: 1px solid #ccc;
    }
  }
  .btn-next {
    span {
      padding: 0 0.6rem;
      border: 1px solid #ccc;
      color: #1f4a89 !important;
    }
  }
  .number {
    border: 1px solid #ccc;
  }
  .el-icon {
    border: 1px solid #ccc;
  }
}
.date{
  color: #333;
  font-size: 2.4rem;
  margin-top: 1rem;
}
</style>
