<template>
	<!--
    <el-container class="honor">
     <el-header class="honor-top">
       <Header />
     </el-header>
     <el-main class="honor-center">
      <Wall title="企业新闻" english="Company News"/>
      <enterpriseNews />
     </el-main>
     <div class="honor-footer">
       <Footer />
      </div>
   </el-container>
   -->
    <div>
       <Header />
      <Wall title="企业新闻" english="Company News"/>
      <enterpriseNews />
       <Footer />
	</div>
 </template>
 
 <script>
 import enterpriseNews from './newsCenter/enterpriseNews.vue'
 import Header from './components/Header.vue'
 import Footer from './components/Footer.vue'
 import Wall from "@/views/components/Wall.vue";
 export default {
 components:{
     Wall,
   Header,Footer,enterpriseNews
 }
 }
 </script>
 
 <style scoped lang="less">
 .honor-center{
   padding: 0 !important;
   display: block !important;
 }
 .honor{
   width: 100%;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   &-top{
     z-index: 999;
     position: fixed;
     top: 0;
     width: 100%;
     background-color:#fff;
     margin: 0;
     padding: 0;
     box-sizing: border-box;
   }

 }
 </style>